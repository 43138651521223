import styled from "styled-components"
import Img from "./Img"
import useViewport from "../utils/Hooks/useViewport"

export default function Label({ text, img, imgWidth, alt, width, height }) {
	const { viewportWidth } = useViewport()
	return (
		<LabelContainer
			width={viewportWidth < 700 ? "100%" : width}
			height={viewportWidth < 700 ? "220px" : height}
		>
			<Img src={img} alt={alt} width={imgWidth || width} />
			<P>{text}</P>
		</LabelContainer>
	)
}

const LabelContainer = styled.article`
	width: ${({ width }) => width};
	padding: 20px;
	height: ${({ height }) => height};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`
const P = styled.p`
	font-weight: 700;
	text-align: center;
	height: 50px;
`
