import styled from 'styled-components'
import Img from './Img'
import StyledLink from './StyledLink'
import logo_light from '../assets/logo-light.png'
import background from "../assets/background-student2.jpg"
import mask from "../assets/mask.png"

export default function Navbar() {
	return (
			<Overlay>
				<Background />
				<Nav>
					<StyledLink to="/"><Img src={logo_light} alt="logo" width="300" /></StyledLink>
					<Title>
						Vous êtes salarié ou demandeur d'emploi ?<br/>
						Découvrez toutes nos formations continues.
					</Title>
				</Nav>
			</Overlay>
	)
}


const Overlay = styled.header`
	background: linear-gradient(to bottom,rgba(39,62,84,0.8),rgba(39,62,84,0.3),transparent); 
	height: 70vh;
`
const Nav = styled.nav`
background: linear-gradient(to bottom,black 1%,transparent,transparent,transparent,transparent);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding-top: 10vh;
`
const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 70vh;
	z-index: -1;
	filter: grayscale(.5);
	background-image: url(${background});
    background-position: 50% 15%;
    background-size: cover;
	background-repeat: no-repeat;
	-webkit-mask: url(${mask}) 100% 100% no-repeat;
	@media screen and (max-width: 700px) {
		height: 60vh;
	}
`
const Title = styled.h1`
	color: #fff;
	width: 70%;
	padding-top: 2rem;
	text-shadow: 10px 10px 20px black;
	text-align: center;
`