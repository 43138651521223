import React from "react"
import ReactDOM from "react-dom/client"
import "./utils/style/index.css"
import { BrowserRouter } from "react-router-dom"
import { Routes, Route } from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop"
import Home from "./pages/Home"
import Cgu from "./pages/Cgu"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Haccp from "./pages/Haccp"
import Excel from "./pages/Excel"
import NotFound from "./pages/NotFound"
import { ViewportProvider } from "./utils/Context/ViewportContext"
// import Info from "./pages/Info"
// import English from "./pages/English"
// import French from "./pages/French"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<ViewportProvider>
				<ScrollToTop />
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/cgu" element={<Cgu />} />
					<Route path="/cgu" element={<Cgu />} />
					<Route path="/formation-haccp" element={<Haccp />} />
					<Route path="/formation-excel" element={<Excel />} />
					<Route path="*" element={<NotFound />} />
					{/* <Route path="/formation-anglais" element={<English />} />
					<Route path="/formation-francais" element={<French />} /> */}
					{/* <Route path="/formation-bases-informatique" element={<Info />} /> */}
				</Routes>
				<Footer />
			</ViewportProvider>
		</BrowserRouter>
	</React.StrictMode>
)

