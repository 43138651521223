import styled from "styled-components"

export default function Avis() {
  return (
	<Container>
		<Review>Très efficace salle de formation accessible cafétéria à l’étage, équipe professionnel et a l’écoute je recommande.</Review>
		<Review>Excellent service de formations. Locaux spatieux :-)</Review>
		<Review>Bon test qui me permet de voir mes lacunes. Certaines fonctions de la dernière version d'excel que je n'ai pas m'ont désavantagé. Je dois bosser certaines formules</Review>
		<Review>Les cours en visio + internet e-learning se complètent parfaitement, et j'ai été ravie de la prise en charge : bonne écoute, échange facile, suivi très satisfaisant. J'encourage fortement les personnes qui souhaitent engager une formation à contacter Format World. Merci et Bonne continuation.</Review>
		<Review>Test très efficace pour avoir une réelle idée de son niveau Excel. Dommage que les bonnes réponses ne soient pas communiquées en fin de test</Review>
		<Review>Très professionnel, formation sur-mesure très pertinente.</Review>
	</Container>
  )
}

const Container = styled.section`
	display: grid;
    grid-template-columns: repeat( auto-fit,minmax(160px,1fr) );
	grid-template-rows: 150px;
    gap: 4rem 3rem;
	min-height: 17rem;
	@media screen and (max-width: 700px) {
		width: 100%;
		min-height: 90px;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 3rem;
	}
`

const Review = styled.article`
	width: 200px;
	height: fit-content;
	min-height: 120px;
	background-color: #f1f1f1;
	border-radius: 5px;
	padding: 1rem;
	position: relative;
    box-shadow: 0px 10px 30px gainsboro;
	:after {
		content: "";
		bottom: -1rem;
		left: 0;
		position: absolute;
		border-top: 2rem solid #f1f1f1;
		border-right: 5rem solid transparent;
		border-bottom: none;
	}
	@media screen and (max-width: 700px) {
		width: 100%;
		min-height: 90px;
	}
`