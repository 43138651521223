import styled from "styled-components"
import pmr from "../assets/icons/acces-pmr.gif"
import Img from "./Img"

export default function Access() {
  return (
	<>
		<Container>
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.381390832447!2d2.3679289156707326!3d48.755512579277536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67417f963ab7f%3A0x13463065d90e023f!2sTour%20Europa!5e0!3m2!1sfr!2sfr!4v1664382429892!5m2!1sfr!2sfr" width="70%" height="400" loading="lazy" title="accès"></iframe>
			<P>
				Tour Europa, 6e étage.<br/> 9 avenue de l'Europe 94320 Thiais.
				<Img src={pmr} width="100px" margin="2rem 0 0 0" alt="accès aux personnes à mobilité réduite" />
			</P>
		</Container>
	</>
  )
}

const Container = styled.section`
	display: flex;
	justify-content : space-between;
	@media screen and (max-width: 700px) {
		flex-direction: column;
	}
	iframe {
		border: none;
		margin-right: 2rem;
		@media screen and (max-width: 700px) {
			width: 100%;
			margin: 0 0 2rem 0;
		}
	}
`
const P = styled.p`
	font-size: 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media screen and (max-width: 700px) {
		flex-direction: row;
		img {
			margin: 0;
		}
	}
`