import React from "react"
import styled from "styled-components"

export default function Cgu() {
	return (
		<Container>
			<H1>Mentions Légales</H1>

			<p>Dénomination : Société FORMAT WORLD</p>
			<p>Siège social : Tour Europa 6ème étage Avenue de l'Europe 94320 Thiais</p>
			<p>Numéro de téléphone : 0787111213</p>
			<p>Adresse e-mail : contact@formatworld.fr</p>
			<p>Registre du commerce et des sociétés RCS Créteil : N°911 105 070</p>
			<p>TVA intracommunautaire : TVA FR43911105070</p>
			<p>Capital social : 1. 000,00€</p>
			<p>Nom du directeur de publication : Dorian Goualard</p>
			<H2>Hébérgement</H2>
			<p>1. Hébergé par 1&1 IONOS</p>
			<p>Raison sociale : 1&1 Internet SARL</p>
			<p>
				Adresse : 1&1 Internet SARL. 7, place de la Gare BP 70109, 57200 Sarreguemines Cedex
			</p>

			<H2>2. Conditions Générales d'Utilisation (CGU)</H2>
			<p>
				L'utilisation du Site par toute personne quel que soit l'objet de sa visite,
				consultation ou mise en relation (ci-après l’« Utilisateur ») est régie par les
				présentes Conditions Générales d'Utilisation (ci-après aussi dénommées « CGU »).
			</p>
			<p>
				Les présentes Conditions Générales d'Utilisation s’appliquent à la première visite
				du Site et sont opposables pendant toute la durée d'utilisation du Site.
			</p>
			<p>
				Les informations contenues sur le Site s’adressent à ses Utilisateurs que ces
				derniers soient des consommateurs et/ou des professionnels.
			</p>
			<p>
				L’Utilisateur est informé que le contenu des pages du site est présenté à titre
				informatif seulement et ne constitue pas une offre de vente ou un démarchage en vue
				de proposer des produits et services précis de la Société FORMAT WORLD.
			</p>
			<p>
				La Société FORMAT WORLD (ci-après la « Société ») se réserve la possibilité de
				modifier et de mettre à jour les Conditions Générales d'Utilisation.
			</p>
			<p>
				Lorsque la Société apportera des modifications, elle mettra en ligne le présent
				document mis à jour quinze (15) jours avant son entrée en vigueur afin de s’assurer
				que les Utilisateurs sont bien informés de ces changements.
			</p>
			<p>
				Ces modifications sont publiées par leur mise en ligne et sont réputées acceptées
				par tout Utilisateur qui y accède postérieurement à leur mise en ligne.
			</p>
			<p>
				La version applicable est celle en vigueur sur le site internet à la date de la
				consultation du site internet formatworld.fr.
			</p>
			<p>
				Il appartient à l’Utilisateur, avant de naviguer sur le site Internet, de lire
				attentivement ces conditions.
			</p>

			<H2>3. Définitions</H2>
			<p>
				Utilisateur : désigne toute personne morale ou physique utilisant le Site à titre de
				consultation des services de formation proposés par le site laformationcpf.com.
			</p>
			<p>
				Contenu : désigne tous œuvres littéraires, propos, messages, informations ou données
				de quelque nature que ce soit (texte, image, vidéos, photographies, commentaires,
				marques, dénominations sociales, etc.), mis en ligne sur le Site.
			</p>
			<p>
				Services : désigne l’ensemble des services présentés par la Société sur le site
				formatworld.fr.
			</p>
			<p>Site : désigne le site internet formatworld.fr.</p>

			<H2>4. Objet des conditions générales d’utilisation</H2>
			<p>
				La Société propose des services de formation dont le contenu est accessible à
				l’adresse formatworld.fr, proposant des services.
			</p>
			<p>
				Les présentes Conditions Générales d’Utilisation ont pour objet de régir les
				conditions et modalités d’utilisation du Site ainsi que les droits et obligations
				des Utilisateurs.
			</p>
			<p>
				Tout accès ou utilisation du Site suppose l’acceptation concomitante et sans réserve
				des termes des présentes conditions.
			</p>
			<p>Le Site s’adresse aux professionnels et Utilisateurs français.</p>
			<p>
				Les Utilisateurs du Site s’engagent à respecter les conditions générales décrites
				ci-après.
			</p>

			<H2>5. Informations</H2>
			<H3>5.1. QUALITÉ :</H3>
			<p>
				Numéro d’enregistrement : 11941130294 Cet enregistrement ne vaut pas agrément de
				l’Etat.
			</p>
			<p>Notre engagement :</p>
			<p>Le respect de la règlementation</p>
			<p>
				L’adéquation des compétences et des moyens techniques et humains aux actions de
				formation
			</p>
			<p>La satisfaction des clients</p>
			<p>La pérennité financière</p>
			<p>Le respect du code de déontologie et du règlement intérieur</p>

			<H2>6. Comportement de l’Utilisateur</H2>
			<p>
				En reconnaissant la nature globale du réseau Internet, chaque Utilisateur s'engage à
				respecter toutes les règles et procédures relatives au comportement en ligne et à un
				contenu acceptable et notamment toutes les lois en vigueur concernant la
				transmission des données techniques.
			</p>
			<p>
				L’Utilisateur s’engage expressément à ne tenir aucun propos, n’avoir aucun
				comportement ou ne publier sur le Site aucun contenu à caractère diffamatoire,
				injurieux, obscène, pornographique, vulgaire, offensant, agressif, déplacé, violent,
				menaçant, harcelant, raciste, xénophobe, à connotation sexuelle, incitant à la
				haine, à la violence, à la discrimination ou à la haine, encourageant les activité
				ou, plus généralement, contraires aux finalités du Site, de nature à porter atteinte
				aux droits de la Société ou d’un tiers ou contraires aux bonnes mœurs.
			</p>
			<p>L’Utilisateur, en accédant au Site s'interdit notamment de :</p>
			<p>
				télécharger vers le Site, afficher, envoyer par courrier électronique ou transmettre
				par tout autre moyen un contenu illégal, préjudiciable, menaçant, injurieux,
				harcelant, délictuel, diffamatoire, vulgaire, obscène, contraire aux bonnes mœurs,
				portant atteinte à la vie privée d'une personne, odieux ou désobligeant ou choquant
				d'un point de vue racial, ethnique ou autre ;
			</p>
			<p>
				télécharger vers le Site, afficher, envoyer par courrier électronique ou transmettre
				par tout autre moyen des éléments publicitaires ou promotionnels non sollicités ou
				non autorisés, des "imprimés publicitaires", des "informations mensongères", des
				"chaînes de lettres", ou toute autre forme de sollicitation ;
			</p>
			<p>
				télécharger vers le Site, afficher, envoyer par courrier électronique ou transmettre
				par tout autre moyen tout élément contenant des virus logiciels ou autres codes,
				fichiers ou programmes informatiques conçus pour interrompre, détruire ou limiter la
				fonctionnalité de tout logiciel ou matériel informatique ou de tout équipement de
				télécommunication ;
			</p>
			<p>
				perturber ou interrompre le Site ou les serveurs ou réseaux connectés au Site, ou
				enfreindre les exigences, procédures, règles ou réglementations des réseaux
				connectés au Site ;
			</p>
			<p>
				essayer de porter atteinte au service de tout Utilisateur, Auteur, hébergeur ou
				réseau, ce qui comprend, sans exhaustivité aucune, le fait d'exposer le Site à un
				virus, de créer une saturation, d'inonder le serveur, de saturer la messagerie
				d'e-mails ou encore de falsifier tout en-tête des informations de l'en-tête figurant
				dans tout e-mail ;
			</p>
			<p>
				accéder aux données qui ne sont pas destinées à l’Utilisateur ou entrer dans un
				serveur / un compte auquel l’Utilisateur n'est pas autorisé à avoir accès ;
			</p>
			<p>
				tenter de sonder, de scruter ou de tester la vulnérabilité d'un système ou d'un
				réseau, ou encore d'enfreindre les mesures de sécurité ou d'authentification sans en
				avoir reçu l'autorisation ;
			</p>
			<p>usurper l'identité d'une autre personne ;</p>
			<p>
				mener une activité ou inciter une tierce personne à mener une activité illégale ou
				toute autre activité qui porterait atteinte aux droits de la Société, de ses
				fournisseurs, partenaires, distributeurs, de ses annonceurs ou de tout autre
				Visiteur ;
			</p>
			<p>
				A tout moment et pour quelque raison que ce soit, la Société peut mettre en œuvre
				tout moyen permettant de mettre un terme, à l'utilisation du Site par l’Utilisateur
				pour toute violation des conditions générales d'utilisation.
			</p>
			<p>
				Tout comportement de l’Utilisateur en violation des présentes Conditions Générales
				d’Utilisation engage sa responsabilité.
			</p>

			<H2>7. Utilisation des services proposés sur le Site formatworld.fr</H2>
			<p>
				L’Utilisateur est seul responsable de la mise en place des moyens informatiques et
				de télécommunications permettant l'accès au Site, et des connaissances nécessaires à
				l'utilisation d'Internet et à l'accès au Site.
			</p>
			<p>
				L’Utilisateur a la charge des frais de connexion et d'équipement liés à l'accès à
				Internet et à l'utilisation du Site.
			</p>
			<p>
				L’Utilisateur s’engage à respecter la réglementation en vigueur, en toute
				connaissance de cause.
			</p>

			<H2>8. Responsabilité de la Société et accès au Site</H2>
			<p>
				La Société met tous les moyens en œuvre pour assurer l’accès et le bon
				fonctionnement du Site de manière continue.
			</p>
			<p>
				Néanmoins, compte tenu des limites liées à Internet, la Société ne peut exclure que
				l’accès et le fonctionnement des Services soient interrompus notamment en cas de
				force majeure, de mauvais fonctionnement des équipements de l’Utilisateur, de
				dysfonctionnements du réseau internet de l’Utilisateur, ou d’opération de
				maintenance destinées à améliorer le Site et les Services.
			</p>
			<p>
				De la même façon, une contrainte liée à l’hébergeur ayant des répercussions sur
				l’accessibilité du site ne peuvent entraîner la responsabilité de la Société.
			</p>
			<p>
				La Société ne garantit ni l’absence de bug, ni la compatibilité avec une
				configuration et/ou matériel particulier, ni un délai de mise en ligne, et ne pourra
				être tenue responsable pour des dysfonctionnements liés à l’utilisation de logiciels
				annexes.
			</p>
			<p>
				La Société ne saurait être tenue responsable d’une interruption des Services, étant
				précisé qu’elle s’engage à faire ses meilleurs efforts pour limiter les
				interruptions qui lui seraient imputables.
			</p>
			<p>
				La Société se réserve le droit, sans préavis, ni indemnité, de changer les méthodes
				opérationnelles, les serveurs et les heures d’accessibilité, de fermer
				temporairement ou définitivement le Site pour effectuer une mise à jour ou des
				modifications.
			</p>
			<p>
				La Société se réserve le droit d’apporter au Site toutes les modifications et
				améliorations qu’elle jugera nécessaires ou utiles dans le cadre du bon
				fonctionnement du Site.
			</p>

			<H2>9. Propriété intellectuelle</H2>
			<p>
				Le Site ainsi que tout logiciel utilisé en relation avec celui-ci peuvent contenir
				des informations confidentielles ainsi que des données protégées par le droit de la
				propriété intellectuelle.
			</p>
			<p>
				Ainsi, sauf mention contraire, les droits de propriété intellectuelle sur les
				documents et toute donnée de toute nature contenus dans le Site et chacun des
				éléments le composant (marques, dessins, images, illustrations, sons, textes,
				éléments graphiques, charte...), y compris les logiciels, bases de données sont la
				propriété exclusive de la Société, celle-ci ne concédant aucune licence, ni aucun
				droit autre que celui de consulter le Site.
			</p>
			<p>
				La reproduction de tout ou partie du Site est seulement autorisée aux fins
				exclusives d'information pour un usage personnel et privé, toute reproduction ou
				utilisation à d'autres fins étant interdite.
			</p>
			<p>
				Toute reproduction intégrale ou partielle, par quelque procédé que ce soit, faite
				sans le consentement préalable et écrit de la Société est illicite et constitue une
				contrefaçon susceptible de donner lieu notamment à des dommages et intérêts et des
				sanctions pénales.Il est interdit de modifier tout ou partie du Site et notamment le
				logiciel, ou d'utiliser des versions modifiées des logiciels en vue d'obtenir un
				accès non autorisé au service, et d'accéder au Site par un autre moyen que
				l'interface qui est fournie à l’Utilisateur par la Société.
			</p>
			<p>
				Il est également interdit de copier, modifier, créer une œuvre dérivée, assembler,
				décompiler (à l'exception des cas prévus par la loi), vendre, extraire, attribuer,
				sous-licencier ou transférer tout afférant au Site.
			</p>
			<p>
				L’Utilisateur s’interdit de porter atteinte, directement ou indirectement, au droit
				de propriété de la Société et s’interdisent d'exploiter de quelque manière que ce
				soit notamment, les noms, les marques les logos.
			</p>
			<p>
				L’Utilisateur s’engage à respecter l'intégralité des droits de la Société et
				s’interdit de susciter toute confusion dans l'esprit du public à quelque titre que
				ce soit.
			</p>

			<H2>10. Données à caractère personnel</H2>
			<p>
				Conformément à la loi dite « Informatique et Libertés » du 6 janvier 1978 modifiée
				et au Règlement européen sur la protection des données personnelles (RGPD) du 27
				avril 2016, l’Utilisateur est informé que la Société procède à des traitements
				automatisés des données à caractère personnel, notamment lors de la connexion au
				Site et de la création de compte.
			</p>
			<p>
				Lors de l'inscription d'un Utilisateur, la Société collecte des données à caractère
				personnel par l’intermédiaire du formulaire disponible à partir de l'encart «
				contactez-nous » du Site : nom, prénom, numéro de téléphone, adresse de messagerie
				électronique.
			</p>
			<p>
				Les informations communiquées sont à destination de la société , afin de rendre les
				Services accessibles à l’Utilisateur et de réponse à ses demandes.
			</p>
			<p>Elles ne peuvent être communiquées, qu’avec l’accord préalable de l’Utilisateur.</p>
			<p>
				La Société s’engage à prendre des mesures techniques et organisationnelles adéquates
				pour assurer la sécurité et la confidentialité de ces données.
			</p>
			<p>
				La Société conserve les données pour une durée de trois ans à compter du dernier
				contact avec l’Utilisateur.
			</p>
			<p>
				Les données à caractère personnel que la Société collecte sont traitées,
				enregistrées et stockées en conformité avec les dispositions légales en vigueur au
				sein de l’Union Européenne.
			</p>
			<p>
				L’Utilisateur dispose des droits d'accès, de limitation, d’effacement, de
				rectification des données le concernant.
			</p>
			<p>
				De même, l’Utilisateur dispose d’un droit d’opposition au traitement des
				informations et données personnelles le concernant, qu’il peut mettre en œuvre pour
				des motifs légitimes, ainsi que d’un droit d’opposition à ce que ces données soient
				utilisées à des fins de prospection commerciale. L’Utilisateur dispose du droit de
				retirer son consentement, à tout moment, sans porter atteinte à la licéité du
				traitement des données personnelles.
			</p>
			<p>
				L’Utilisateur dispose enfin du droit de définir des directives générales et
				particulières définissant la manière dont il entend que soient exercés, après son
				décès, ses droits.
			</p>
			<p>
				Pour exercer ses droits, l’Utilisateur peut écrire à l’adresse suivante : FORMAT
				WORLD tour Europa 6ème étage 9 avenue de l'Europe Thiais 94320, accompagné d’une
				copie d’un titre d’identité signé.
			</p>
			<p>
				Le cas échéant, si vous considérez que les réponses apportées à vos demandes ne sont
				pas suffisantes vous pouvez également saisir la CNIL. La Société invite à la
				contacter au préalable à l’adresse mail suivante : contact@formatworld.fr
			</p>
			<p>
				L’ensemble des informations relatives à la collecte de données à caractère personnel
				est disponible au sein de la charte des données à caractère personnel insérée au
				sein des CGV.
			</p>

			<H2>11. Liens hypertextes</H2>
			<p>
				Le Site peut inclure des liens vers d'autres sites Internet ou d'autres sources
				Internet. La Société ne peut contrôler ces sites et ces sources externes, et ne peut
				être tenue responsable de la mise à disposition de ces sites et sources externes, et
				ne supporte aucune responsabilité quant à leurs contenu, publicités, produits,
				services ou toute autre information ou donnée disponible.
			</p>
			<p>
				La Société ne pourra être tenue responsable de tous dommages ou pertes avérés ou
				allégués consécutifs à la confiance accordée au contenu, aux biens ou aux services
				disponibles sur ces sites ou sources externes ou à leur utilisation.
			</p>
			<p>
				Toute création de liens vers le Site formatworld.fr, et plus généralement toute
				utilisation d'un élément composant le Site, est soumise à l'autorisation préalable
				et expresse de la Société.
			</p>
			<p>
				La Société se réserve le droit de demander la suppression de tout lien vers le Site
				qui n'aurait pas été, ou ne serait plus autorisé et de demander des dommages et
				intérêts en réparation du préjudice subi.
			</p>

			<H2>12. Politique des cookies</H2>
			<p>
				La Société informe les Utilisateurs, à travers ces conditions générales
				d’utilisation, qu’elle peut utiliser des cookies lorsque l’internaute navigue sur
				les différentes interfaces et pages du Site ainsi que lors du processus
				d’enregistrement, le téléchargement et la mise à jour de celui-ci.
			</p>
			<p>
				Pendant l’usage du Site, l’Utilisateur accepte et autorise expressément l’usage de
				cookies, en accord avec notre Politique de Cookies.
			</p>
			<p>
				En paramétrant votre navigateur pour refuser les cookies, certaines fonctionnalités,
				pages, espaces du Site ne seront pas accessibles, ce dont nous ne saurions être
				responsables.
			</p>
			<p>
				Nous attirons également votre attention sur le fait que, lorsque vous vous opposez à
				l’installation ou à l’utilisation d’un cookie, un cookie de refus est installé sur
				votre équipement terminal. Si vous supprimez ce cookie de refus, il ne sera plus
				possible de vous identifier comme ayant refusé l’utilisation de cookies. De même,
				lorsque vous consentez à l’installation de cookies, un cookie de consentement est
				installé. Les cookies de consentement ou de refus doivent rester sur votre
				équipement terminal.
			</p>
			<p>
				La Société peut modifier la Politique de Cookies conformément à la loi, la
				réglementation ou afin d’adapter la politique aux instructions émises par CNIL.
			</p>
			<p>
				L’Utilisateur doit consulter régulièrement la politique des cookies pour être tenu
				informé des changements.
			</p>

			<H3>12.1 LES COOKIES</H3>
			<p>
				Lorsque l’Utilisateur se connecte sur le site formatworld.fr, la Société peut être
				amenée, sous réserve des choix de l’Utilisateur, à installer divers cookies dans son
				terminal lui permettant de reconnaître le navigateur du terminal pendant la durée de
				validité du cookie concerné. Les Cookies émis sont utilisés aux fins décrites
				ci-dessous, sous réserve des choix de l’Utilisateur, qui résultent des paramètres de
				son logiciel de navigation, utilisé lors de sa visite sur le Site.
			</p>
			<p>Les Cookies émis par la Société permettent :</p>
			<p>
				D'établir des statistiques et volumes de fréquentation et d'utilisation des diverses
				éléments composant notre site (rubriques et contenus visités, parcours), lui
				permettant d'améliorer l'intérêt et l'ergonomie de ses services
			</p>
			<p>
				D'adapter la présentation du Site aux préférences d'affichage du terminal (langue
				utilisée, résolution d'affichage, système d'exploitation utilisé, etc.) lors des
				visites sur son Site, selon les matériels et les logiciels de visualisation ou de
				lecture que le terminal de l’Utilisateur comporte
			</p>
			<p>
				De mémoriser des informations relatives à un formulaire rempli sur le Site
				(inscription ou accès à votre compte) ou à des services ou informations que vous
				choisis sur le Site
			</p>
			<p>
				D'accéder à des espaces réservés et personnels du site, tels que le compte, grâce à
				des identifiants ou des données antérieurement confiés
			</p>
			<p>
				De mettre en œuvre des mesures de sécurité, par exemple lorsqu'il vous est demandé
				de vous connecter à nouveau à un contenu ou à un service après un certain laps de
				temps.
			</p>

			<H3>12.2 LES COOKIES ÉMIS PAR DES TIERS</H3>
			<p>
				L'émission et l'utilisation de cookies par des tiers, sont soumises aux politiques
				de protection de la vie privée de ces tiers.
			</p>
			<p>
				Les contenus publicitaires (graphismes, animations, vidéos, etc) diffusés dans nos
				espaces publicitaires sont susceptibles de contenir des Cookies émis par des tiers :
				soit l'annonceur à l'origine du contenu publicitaire concerné, soit une société
				tierce à l'annonceur (agence conseil en communication, société de mesure d'audience,
				prestataire de publicité ciblée, etc.), qui a associé un cookie au contenu
				publicitaire d'un annonceur.
			</p>
			<p>
				Le cas échéant, les cookies émis par ces tiers peuvent leur permettre, pendant la
				durée de validité de ces cookies :
			</p>
			<p>
				De comptabiliser le nombre d'affichages des contenus publicitaires diffusés via nos
				espaces publicitaires,
			</p>
			<p>
				D'identifier les publicités ainsi affichées, le nombre d'utilisateurs ayant cliqué
				sur chaque publicité, leur permettant de calculer les sommes dues de ce fait et
				d'établir des statistiques
			</p>
			<p>
				De reconnaître votre terminal lors de sa navigation ultérieure sur tout autre site
				ou service sur lequel ces annonceurs ou ces tiers émettent également des cookies et,
				Le cas échéant, d'adapter ces sites et services tiers ou les publicités qu'ils
				diffusent, à la navigation de votre terminal dont ils peuvent avoir connaissance.
			</p>
			<p>
				Pour plus d'information sur les cookies et leur utilisation, l’Utilisateur peut
				consulter le Site de la Commission Nationale de l'Informatique et des Libertés à
				l'adresse suivante : http://www.cnil.fr.
			</p>

			<H2>13. Médiation</H2>
			<p>
				En cas de différend avec la Société, non réglé à l’amiable dans un délai de 45 jours
				à compter de la notification écrite du différend par l’Utilisateur non
				professionnel, ce dernier, s’il le souhaite, peut recourir à une procédure de
				médiation conventionnelle notamment auprès de la Commission de la médiation de la
				consommation (article L 612-1 du code de la consommation) ou à tout mode alternatif
				de règlement des différends (conciliation, par exemple) en cas de contestation.
			</p>
			<p>
				L’Utilisateur non professionnel peut saisir le médiateur dans un délai d’un an à
				compter de la notification écrite du différend. A défaut sa demande sera forclose.
			</p>
			<br />
			<p>Loi applicable et tribunaux compétents</p>
			<p>Le Site formatworld.fr est soumis à la loi française.</p>
			<br />
			<p>Les tribunaux français seront seuls compétents en cas de litige.</p>
			<br />
			<p>
				Tous les litiges auxquels l’application des présentes conditions pourraient donner
				lieu, concernant tant leur validité, leur interprétation, leur exécution, leur
				résiliation, leurs conséquences et leurs suites et qui n'auraient pu être résolues
				entre les parties seront soumis aux tribunaux compétents dans les conditions de
				droit commun. En cas de contestation judiciaire, le litige sera de la compétence
				exclusive des tribunaux français compétents pour un différend opposant la Société à
				un non professionnel.
			</p>
		</Container>
	)
}

const Container = styled.div`
	width: 90%;
	margin: 2rem auto;
`
const H1 = styled.h1`
	margin: 2rem 0;
`
const H2 = styled.h2`
	margin: 2rem 0;
`
const H3 = styled.h3`
	margin: 2rem 0;
`
