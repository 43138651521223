import styled from "styled-components"
import whiteLogo from "../assets/logo-light.png"
import Img from "../components/Img"
import StyledLink from "../components/StyledLink"

export default function Footer() {
	const year = new Date().getFullYear()

	return (
		<FooterContainer>
			<div>
				<StyledLink to="/">
					<Img src={whiteLogo} width="10rem" alt="logo footer" margin="0 auto 1rem" />
				</StyledLink>
				<p>© {year} Format World. All rights reserved.</p>
				<p>Action de formation continue</p>
			</div>
			<RightContainer>
				<div>
					<p style={{ marginBottom: "1rem" }}>
						Tour Europa, 6e étage. 9 avenue de l'Europe 94320 Thiais.
					</p>
					<StyledLink to="/cgu" color="#fff">
						Conditions générales d'utilisation
					</StyledLink>
				</div>

				<ContactContainer>
					<A href="tel:+33787111213">Contactez nous au : 0787111213</A>
					<A href="mailto:contact@formatworld.fr">
						ou&nbsp;par&nbsp;mail :&nbsp;contact@formatworld.fr
					</A>
				</ContactContainer>
			</RightContainer>
		</FooterContainer>
	)
}

const FooterContainer = styled.footer`
	background-color: black;
	text-align: center;
	color: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	padding: 2rem;
	gap: 2rem;
`
const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 130px;
	justify-content: space-around;
`
const ContactContainer = styled.div`
	width: 100%;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`

const A = styled.a`
	margin-bottom: 5px;
`
