import styled from "styled-components"
import visuel_cpf from "../assets/visuel-formation-financable-cpf.jpg"
import logo_cpf from "../assets/logo-cpf.jpg"
import Img from "../components/Img"
import useViewport from "../utils/Hooks/useViewport"

export default function CPF() {
	
	const { viewportWidth } = useViewport()

  	return (
		<CPFcontainer>
			<BlueLine/>
			<Img src={visuel_cpf} alt="formation-financable-cpf" width={viewportWidth<500 ? "100%" : "200px"} />
			<div style={{width: viewportWidth<500 ? "100%" : "50%", padding:"0 2rem"}}>
				<h2 style={{fontSize: "2rem", margin: viewportWidth<500 ? "1rem auto" : "0"}}><BlueSpan>FORMATIONS </BlueSpan><RedSpan>100% FINANCABLES</RedSpan><BlueSpan> VIA VOTRE CPF&nbsp;!</BlueSpan></h2>
				<P color="#808080" padding="1rem 0">Le CPF permet à toute personne, salariée ou demandeur d’emploi, de suivre la formation de leur choix.</P>
			</div>
			<Img src={logo_cpf} alt="logo_cpf" width="200px" height="200px" margin={viewportWidth<500 ? "20px auto" : "20px"} />
			<RedLine/>
		</CPFcontainer>
  )
}

const BlueLine = styled.div`
	width: 100%;
	height: 10px;
	background-color: #0b3f89;
	margin: 2rem 0;
`
const RedLine = styled.div`
	width: 100%;
	height: 10px;
	background-color: #d71a22;
	margin: 2rem 0;
`
const CPFcontainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
    align-items: center;
`
const BlueSpan = styled.span`
	color: #0b3f89;
`
const RedSpan = styled.span`
	color: #d71a22;
`
const P = styled.p`
	color:  ${({ color }) => color};
	padding:  ${({ padding }) => padding};
	font-size: 1.1rem;
`