import Card from "../components/Card"
import logo_qualiopi from "../assets/logo-qualiopi.jpg"
import logo_datadock from "../assets/logo-datadock.jpg"
import logo_cpf from "../assets/logo-cpf.jpg"
import logo_apave from "../assets/logo_apave.png"
import logo_plateforme from "../assets/icons/platform.png"
import logo_video from "../assets/icons/video.svg"
import logo_coach from "../assets/icons/coach.svg"
import logo_pedagogie from "../assets/icons/pedagogie.svg"
import logo_agenda from "../assets/icons/agenda-numerique.svg"
import haccp_picture from "../assets/formation-haccp.jpg"
// import english_picture from "../assets/formation-anglais.jpg"
// import french_picture from "../assets/formation-francais.jpg"
import excel_picture from "../assets/formation-excel.jpg"
// import informatique_picture from "../assets/formation-informatique.webp"
import styled from "styled-components"
import Label from "../components/Label"
import CPF from "../components/CPF"
import Access from "../components/Access"
import Avis from "../components/Avis"

export default function Home() {
	return (
		<Main>
			<LabelContainer>
				<Label
					text="Organisme de formation professionnelle certifié qualité Qualiopi"
					img={logo_qualiopi}
					alt="logo-qualiopi"
					width="200px"
					height="250px"
				/>
				<Label
					text="Organisme Validé et Référencé"
					img={logo_datadock}
					alt="logo-datadock"
					width="130px"
					height="250px"
				/>
				<Label
					text="Formations finançables avec votre CPF"
					img={logo_cpf}
					alt="logo-cpf"
					width="120px"
					height="250px"
				/>
				<Label
					text="Certifié par l'organisme Apave"
					img={logo_apave}
					alt="logo-apave"
					width="200px"
					height="250px"
				/>
			</LabelContainer>
			<H2>
				Formez-vous <Strong>facilement</Strong> à votre <Strong>futur</Strong> métier !
			</H2>
			<CardContainer>
				<Card title="Formation Haccp" pictureSrc={haccp_picture} link="/formation-haccp" />
				{/* <Card title="Formation Anglais" pictureSrc={english_picture} link="/formation-anglais"/>
				<Card title="Formation Francais" pictureSrc={french_picture} link="/formation-francais"/> */}
				<Card title="Formation Excel" pictureSrc={excel_picture} link="/formation-excel" />
				{/* <Card title="Formation Bases Informatique" pictureSrc={informatique_picture} link="/formation-bases-informatique"/> */}
			</CardContainer>
			<H2>
				Notre parcours <Strong>pédagogique</Strong>
			</H2>
			<LabelPedagogieContainer>
				<Label
					text="Plateforme ludique et accessible"
					img={logo_plateforme}
					alt="logo-plateforme"
					imgWidth="80px"
					width="150px"
					height="200px"
				/>
				<Label
					text="Tutoriels pédagogiques et webinaires"
					img={logo_video}
					alt="logo-video"
					imgWidth="70px"
					width="150px"
					height="200px"
				/>
				<Label
					text="Accompagnement personnalisé et régulier"
					img={logo_coach}
					alt="logo-coach"
					imgWidth="70px"
					width="150px"
					height="200px"
				/>
				<Label
					text="Permanences et corrections des devoirs"
					img={logo_pedagogie}
					alt="logo-pedagogie"
					imgWidth="70px"
					width="150px"
					height="200px"
				/>
				<Label
					text="Planification de votre parcours de formation"
					img={logo_agenda}
					alt="logo-agenda-numerique"
					imgWidth="70px"
					width="150px"
					height="200px"
				/>
			</LabelPedagogieContainer>
			<CPF />
			<H2>Avis</H2>
			<Avis />
			<H2>Accès</H2>
			<Access />
		</Main>
	)
}

const Main = styled.main`
	margin: 5rem 3rem;
	@media screen and (max-width: 700px) {
		margin: 0 1rem 5rem;
	}
`
const LabelContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
`
const LabelPedagogieContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	@media screen and (max-width: 700px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		article {
			height: 180px;
		}
	}
`
const H2 = styled.h2`
	font-family: "Rubik", Arial, sans-serif;
	font-size: 2rem;
	position: relative;
	margin: 5rem 0rem;
	text-align: center;
	color: #0b3f89;
	font-weight: 300;
	:after {
		content: "";
		position: absolute;
		bottom: -20px;
		left: 10%;
		right: 10%;
		background: linear-gradient(111.3deg, transparent -2%, #0b3f89 50%, transparent 100%);
		height: 3px;
	}
`
const Strong = styled.span`
	font-weight: 500;
`
const CardContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 3rem;
`
