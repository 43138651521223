import styled from 'styled-components'

export default function Haccp() {
  return (
	<Main>
		<Aside>
			<H1>Formation Hygiène et sécurité alimentaire</H1>
			<CardContainer>
				<Card>
					<H2>Suivi et évaluation :</H2>
					<Content>
						Questionnaire d’auto-positionnement est adressé aux stagiaires en amont de la formation pour connaitre les besoins.<br/><br/>
						Évaluation sous forme de QCM, question ouverte et tout le long de la formation.
					</Content>
				</Card>
				<Card>
					<H2>Durée et Tarif :</H2>
					<Content>
						14 heures.<br/><br/>
						Tarif : 1499€<br/><br/>
						Prérequis: <br/>Avoir un ordinateur et une connexion internet.
					</Content>
				</Card>
				<Card>
					<H2>Personnes en situation de handicap :</H2>
					<Content>
						Vous êtes en situation de handicap et souhaitez faire une formation ?<br/><br/>
						Merci de bien vouloir nous contacter en amont afin d’étudier vos besoins ensemble.
					</Content>
				</Card>
			</CardContainer>
		</Aside>
		<div style={{margin: "0 2rem"}}>

				<H2>Modalité et délais d’accès à la formation :</H2>
					<p>
						Toutes nos formations sont réalisées à la demande en fonction des souhaits de nos clients.<br/>
						Nous pouvons également réaliser des formations sur mesure à partir de programme existant ou en construisant un programme spécifique à partir de vos objectifs.<br/>
						Merci de bien vouloir nous contacter par courriel ou par téléphone afin de définir ensemble les dates et modalités de formation souhaitées.<br/>
						À la suite de notre contact la session pourra être programme sous deux semaines maximum.<br/>
					</p>

				<H2>Contenu de la formation :</H2>
					<p>
						Comprendre et intégrer les fondamentaux, d'une politique globale de Sécurité Alimentaire pour tous les membre de l'entreprise.<br/>
						Acquérir les méthodes et outils nécessaires à la mise en œuvre de la démarche.<br/>
						Etre en capacité de réaliser la mise en œuvre du processus global, en intégrant les différentes méthodes disponibles 5M.<br/>
						Identifier les grands principes de la réglementation en relation avec la restauration commerciale.<br/>
						Analyser les risques liés a une insuffisance d'hygiène en restauration commerciale mais aussi mettre en œuvre les principes d'hygiène.<br/>
					</p>

				<H2>Objectif de formation :</H2>
					<p>
						Obtenir une certification en fin de formation (sous 15 jours).<br/>
						Attestation de formation conforme : Décret n°2011-731 du 24 juin 2011 relatif a l'obligation de formation.<br/>
						en matière d'hygiène alimentaire de certains établissement de restauration commerciale et arrêté du 13 juin 2016 relatif au cahier des charges.
					</p>

			</div>
	</Main>
  )
}

const Main = styled.main`
	margin: 4rem 2rem;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 700px) {
		flex-wrap: wrap;
		img {
			width: 100%;
			margin-bottom: 2rem;
		}
		margin: 0 0 4rem;
	}
	h2:nth-child(1) {
		margin: 0 0 1rem;
	}
`
const Aside = styled.aside`
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 700px) {
		margin: 0 2rem;
	}
`
const H1 = styled.h1`
	font-family: "Rubik",Arial,sans-serif;
	position: relative;
	margin: 0 2rem;
	text-align: center;
	color: #91c3c7;
	font-weight: 400;
    font-size: 2rem;
	:after {
	    content: "";
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		background: linear-gradient(111.3deg, #91c3c7 50%, transparent 100%);
		height: 3px;
	}
	@media screen and (max-width: 700px) {
		:after {
			top: 160px;
		}	
	}

`
const CardContainer = styled.section`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 3rem 2rem 2rem;
    gap: 2rem;
	@media screen and (max-width: 700px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: 4rem 0;
	}
`
const Card = styled.article`
	box-shadow: 0px 0px 5px gray;
	min-height: 300px;
	max-width: 400px;
	padding: 2rem;
`
const H2 = styled.h2`
	color: #91c3c7;
	margin: 2rem 0 1rem;
`
const Content = styled.p`
	font-size: 1.2rem;
`